.toolbar {
	background: $lightestGrey;
	
	.title-bar {
		background: $ms-darkGrey;
		height: 40px;
		font-weight: 600;
		padding: 10px 12px;

		p {
			color: white;
		}
	}

    .top-bar {
        min-height: 44px;
    }

	.collapse-menu {
		margin-left: 8px;

		button {
			height: 44px;

			i {
				color: rgb(50, 49, 48);
				font-size: 1.4em;
			}
		}
	}

	.history-actions {
		margin-left: 8px;

		.home {
			i {
				font-size: 22px;
			}
		}

		button {
			height: 44px;
			width: 30px;
			vertical-align: top;

			&:not(.is-disabled) {
				i { color: $darkGrey; }

				&:hover {
					background: $hoverGrey;
				}
			}
		}
	}

	.refresh-action {
		margin-left: 8px;

		button {
			height: 44px;

			i {
				font-size: 1.1em;
				margin-top: 1px;
			}

			&:not(.is-disabled) {
				i { color: $darkGrey; }

				&:hover {
					background: $hoverGrey;
				}
			}
		}
		.ms-Spinner-wrap {
			//margin-top: 10px;
			margin: 11px 4px 0 4px;
			width: 24px;

			.ms-Spinner {
				.ms-Spinner-circle {
					border-color: $lightGrey $grey $grey;
				}
			}
		}
	}

	.refresh-button {
		height: 44px;
		i {
			font-size: 1.1em;
			margin-top: 1px;
		}
	}


	.folders-breadcrumb {
		border: 1px solid $lightGrey;
		height: 34px;
		margin: 5px 8px 0 8px;
		max-width: 100%;

		.breadcrumb-icon {
			//display: inline-table;
			margin-left: 8px;
			color: $darkGrey;
			font-size: 1.4em;
			line-height: 32px;
			vertical-align: te;
		}

		.ms-Breadcrumb {
			margin: 0;

			.ms-Breadcrumb-itemLink {
				border: none;
				&:hover {
					background: $hoverGrey;
				}
			}

			span, button, a, i {
				color: $darkGrey;
				line-height: 32px;
			}
			span, button, a {
				font-size: 14px;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.persona {
		margin-top: 6px;

		.ms-Persona-details {
			display: none
		}
	}

	.sync-status {
		span {
			font-size: 0.7rem;
			margin-top: 2px;
			vertical-align: text-top;
		}

		.ms-Spinner {
			display: inline-table;

			.ms-Spinner-circle {
				border-color: white $lightGrey $lightGrey;
			}
		}
	}

	.downloads-panel {
		margin-left: 8px;
		margin-right: 0;

		&:hover {
			background: $hoverGrey;
			cursor: pointer;
		}

		button {
			height: 44px;
			width: 30px;

			&:not(.is-disabled) {
				i { color: $darkGrey;; }

				&:hover {
					background: $hoverGrey;
				}
			}
		}
	}

	.imports-panel {
		margin-left: 8px;
		margin-right: 0;

		.creatingFolders  {
			.ms-Spinner-circle {
				border-color: #ffda6d #ffb300 #ffb300;
			}
		}

		&:hover {
			background: $hoverGrey;
			cursor: pointer;
		}

		button {
			height: 44px;
			width: 30px;

			&:not(.is-disabled) {
				i { color: $darkGrey;; }

				&:hover {
					background: $hoverGrey;
				}
			}
		}
	}

	.right-panel-trigger {
		margin-left: 8px;
		margin-right: 0;

		button {
			height: 44px;
			width: 30px;

			&:not(.is-disabled) {
				i { color: $darkGrey;; }

				&:hover {
					background: $hoverGrey;
				}
			}
		}
	}
}

.commandBar {
	border-bottom: 1px solid $lightGrey;

	.ms-CommandBar {
		background: $lightestGrey;
		margin: 0;
		padding: 0;
	}

	.ms-Button {
		background: $lightestGrey;
		height: 38px;
		border-radius: 2px;

		&:hover {
			background: $hoverGrey;
		}
	}

	.ms-SearchBox-clearButton {
		.ms-Button {
			background: $lightestGrey;
			height: 32px;
			border-radius: 0;
	
			&:hover {
				background: $hoverGrey;
			}
		}
	}

	.sidePanel-button {
		width: 48px;

		i {
			color: $darkGrey !important;
			font-size: 1.8em;
			font-weight: 550;
			padding-right: 2px;
		}
	}

	.sidePanel-button-right {
		width: 48px;

		i {
			color: $darkGrey !important;
			font-size: 1.8em;
			font-weight: 550;
		}
	}
}