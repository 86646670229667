#main-nav {
	height: calc(100vh - 89px - 40px);
	position: relative;
	nav{
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}
}

.child-elements {
  pointer-events: none;
}

nav {
	z-index: 1;
	
	&.main {
		background: $ms-darkGrey;
		min-width: 48px;
		overflow: auto;

		
		button {
			padding: 0 6px;
			border-radius: 0;
		}

		i {
			font-size: 1.8em;
			font-weight: 550;
		}

		.ms-Button:not(.is-selected),
		.ms-Nav-compositeLink:not(.is-selected) {
			i {
				color: white;
			}

			&:hover {
				background: $lightestGrey;
				i {
					color: $darkGrey
				}
			}
		}

		.ms-Nav-compositeLink.is-disabled {
			i { color: #a4a4a4 }

			&:hover {
				background: transparent;
				i {
					color: #a4a4a4
				}
			}
		}

		.is-selected {

			&:focus {
				outline: none;
			}

			button {
				background: $lightestGrey;

				span i {
					color: $darkGrey !important;
				}

				&:after {
					border: none;
				}
			}
		}

		.fixed-bottom {
			position: relative;
			margin-top: auto;
			margin-bottom: 0;
			bottom: 0;
			button {
				height: 42px;
			}
			width: 48px;
		}
	
	}

	&.tree {
		background: $lightestGrey;
		border-right: 1px solid $ms-lightGrey;
		height: 100%;
		width: 100%;
		overflow: auto;

		p {
			font-weight: 650;
			padding: 12px;

			&.title {
				font-size: 1rem;
				width:calc(100% - 16px);
				text-align: left;
				background: $lightestGrey;
				position: absolute;
				z-index: 1;
			}
		}

		.ms-Nav {
			display: inline-table;
			width: 100%;
		}

		.ms-Nav-groupContent {
			margin-bottom: 10px;
		}

		.ms-FocusZone {
			margin-top: 48px
		}

		.ms-Nav-link > * > .ms-Button-icon:not(:empty) {
		    margin-left: 28px;
		}

		.ms-Nav-compositeLink {
			height: 32px;

			&:hover {
				background: $hoverGrey;
			}

			button, i {
				height: 32px;
				line-height: 34px;
			}
		}

		.folderLink {
			text-align: left;
			width: 100%;

			&.dir {
				padding: 0 12px;
			}
			
			a, i {
				color: $darkGrey;

				&:hover {
					color: $darkGrey;
				}

				&.edit {
					display: none;
					&:hover {
						color: $highlight;
					}
				}

				&.delete {
					display: none;
					&:hover {
						color: red;
					}
				}
			}

			&.pendingUploadConflict {
				color: red !important;

				i {
					color: red !important;
				}
			}

			&:hover {
				.edit, .delete {
					display: inline-block;
				}
			}

			i {
				height: 20px;
				margin-right: 12px;
				vertical-align: text-bottom;
				width: 20px;

				&.repoIcon {
					color: $ms-blue;
					font-size: 1.4em;
					vertical-align: top;
				}

				&.openFiles {
					color: $darkGrey;
					font-size: 1.4em;
					vertical-align: top;
				}

				&.offlineFiles {
					color: $darkGrey;
					font-size: 1.4em;
					vertical-align: top;
				}

				&.smart-folder {
					color: $darkGrey;
					font-size: 1.4em;
					vertical-align: top;
				}

				&.tag,
				&.fileType {
					color: $darkGrey;
					font-size: 1.4em;
					vertical-align: top;
				}

				&.pdf { color: #bf2a1f }
				&.word { color: #1951b0 }
				&.excel { color: #16713e }
				&.powerpoint { color: #bb3922 }
				&.email { color: #367ecf }


				img {
					vertical-align: top;
				}
			}
		}

		.usersFilter,
		.fileExplorer {
			
			.ms-Nav-compositeLink {
				&:hover {
					background: $hoverGrey;

					span {
						//font-weight: 600 !important;
						color: rgb(50, 49, 48);
					}



					button {
						background: transparent;

						&:after {
							border: none;
						}

						i {
							color: $darkGrey;
						}
					}
				}
			}
			
			.is-selected {
				background: $selectedGrey !important;

				span {
					color: $ms-blue !important;
					//font-weight: 400 !important;
				}
			}

			.selUser,
			.selFolder {
				span {
					font-weight: 600 !important;
					color: $ms-blue;
				}

				&.pendingUploadConflict span {
					color: red;
				}
			}
		}

		.tagFilter {
			.is-selected {
				button {
					font-weight: 400;
				}
			}

		}

		.filter-selected,
		.smartFolder-selected {
			color: rgb(0, 120, 212);
			font-weight: 600;
		}

		.ms-Nav-compositeLink {
			button.ms-Nav-chevronButton i {
				transform: rotate(-90deg);
			}

			&.is-expanded {
				button.ms-Nav-chevronButton i {
					transform: rotate(0deg);
				}
			}
		}

		.is-selected {
			background: $themeLight;

			span {
				font-weight: 600 !important;
				color: $ms-blue;
			}

			button {
				background: transparent;

				&:after {
					border: none;
				}

				i {
					color: $darkGrey;
				}
			}
		}

		&.search {
			li.ms-Nav-navItem {
				margin-bottom: 10px;

				.ms-Nav-navItem {
					margin-bottom: 0;
				}
			}
		}
	}

	&.tree-virtual {
		background: $lightestGrey;
		border-right: 1px solid $ms-lightGrey;
		height: 100%;
		width: 100%;
		overflow: auto;

		.ms-Nav-navItems > div:first-of-type {
			width: 100% !important;
			height: 100% !important;
		}
	
		ul {
			width: 100%;
		}
	
		p {
			font-weight: 650;
			padding: 12px;
	
			&.title {
				background: $lightestGrey;
				font-size: 1rem;
				position: absolute;
				width: calc(100% - 11px);
				z-index: 1;
			}
	
			&.nav-repo-group {
				font-size: 0.9rem;
				padding-left: 24px;
			}
		}
	
		span.title {
			font-size: 1rem;
			font-weight: 650;
		}
	
		.ms-Nav {
			display: inline-table;
			width: 100%;
		}
	
		.ms-Nav-groupContent {
			margin-bottom: 0;
		}
	
		.ms-FocusZone,
		.ms-ComboBox-container,
		.list-page {
			.ReactVirtualized__Grid__innerScrollContainer {
				margin-top: 44px;
				overflow:  visible !important;
			}
		}
		
		.ReactVirtualized__Grid.ReactVirtualized__List {
			overflow:  auto !important;
			height: 100% !important;
		}
	
		&.entity {
			.ReactVirtualized__Grid.ReactVirtualized__List {
				overflow:  hidden !important;
				height: 100% !important;
			}
		}
	
		.ReactVirtualized__Grid__innerScrollContainer {
			.ms-Nav-navItem {
				width: auto !important;
				.custom-view.ms-Button.ms-Nav-link {
					color: $ms-blue;
					&:hover {
						background-color: $lightGrey !important;
					}
				}
				.filter-category {
					opacity: 0;
					color: $ms-blue;
					&:hover {
						background-color: $selectedGrey;
					}
				}
				&:hover {
					.filter-category {
						opacity: 1 !important;
					}
				}
	
				&.bg-selected{
					background-color: $selectedGrey !important;
					.ms-Nav-compositeLink {
						background-color: $selectedGrey !important;
					}
					.ms-Button {
						background-color: $selectedGrey !important;
					}
				}
			}
			li {
				height: 34px;
				list-style: none;
				right: 0;
	
				&:hover {
					background: $hoverGrey;
				}
			}
	
			button {
				background-color: transparent;
			}
	
			.selItem,
			.scrolledFolder {
				
				span,.ms-Persona-primaryText {
					font-weight: 600;
					color: $ms-blue;
				}
				.ms-Persona-initials {
					span {
						color:inherit !important;
						font-weight: inherit;
					}
				}
	
				&.pendingUploadConflict span {
					color: red;
				}
	
				&.templates i {
					color: #2c2c2c;
				}
			}
		}
	
		.ms-Nav-link > * > .ms-Button-icon:not(:empty) {
			margin-left: 28px;
		}
	
		.ms-Nav-compositeLink {
			height: 32px;
			width: 100%;
	
			&:hover {
				background: $hoverGrey;
			}
	
			button, i {
				height: 32px;
				line-height: 34px;
			}
	
			&.is-selected {
				color: $selectedGrey;
	
				.folderLink.tagCategory {
					color: $ms-blue;
				}
			}
		}
	
		.folderLink {
			text-align: left;
			width: 100%;
			display: flex;
			align-items: center;
			height: 100%;
	
			&.dir {
				padding: 0 6px;
			}
	
			&.settings {
				&.selItem {
					span,.ms-Persona-primaryText {
						font-weight: 600;
						color: $ms-blue;
					}
					.ms-Persona-initials {
						font-weight: inherit;
						color: yellow !important;
					}
					
				}
	
				&:hover {
					span {
						color: $darkGrey !important;
						font-weight: 600;
					}
				}
			}
			
			a, i, .allPublicLinks {
				color: $darkGrey;
	
				&:hover {
					color: $darkGrey;
				}
	
				&.edit {
					display: none;
					&:hover {
						color: $highlight;
					}
				}
	
				&.delete {
					display: none;
					&:hover {
						color: red;
					}
				}
			}
	
			.allPublicLinks {
				font-weight: 600;
			}
	
			&.pendingUploadConflict {
				color: red !important;
	
				i {
					color: red !important;
				}
			}
	
			&:hover {
				.edit, .delete {
					display: inline-block;
				}
			}
	
			i {
				//height: 20px;
				margin-right: 12px;
				//vertical-align: text-bottom;
				width: 20px;
	
				&.repoIcon {
					font-size: 1.4em;
					vertical-align: top;
				}
	
				&.repoIcon,
				&.recentFiles,
				&.openFiles,
				&.bookmarks,
				&.offlineFiles,
				&.smart-folder,
				&.tag,
				&.fileType,
				&.publicLink {
					color: $darkGrey;
					font-size: 1.4em;
					vertical-align: top;
				}
	
				&.pdf { color: #bf2a1f }
				&.word { color: #1951b0 }
				&.excel { color: #16713e }
				&.powerpoint { color: #bb3922 }
				&.email { color: #367ecf }
	
	
				img {
					vertical-align: top;
				}
			}
	
			.icon-wrap {
				display: inline-block;
				position: relative;
	
				.folder-in-path,
				.no-access-folder {
					font-size: 0.5em;
					left: 11px;
					position: absolute;
					top: 1px;
				}
	
				.folder-in-path {
					color: $highlight;
				}
			}
		}
	
		.fileExplorer {
			.is-selected span{
				font-weight: 400;
			}
	
			.selItem,
			.scrolledFolder {
				span {
					font-weight: 600;
					color: $ms-blue;
				}
	
				&.pendingUploadConflict span {
					color: red;
				}
	
				&.templates i {
					color: #2c2c2c;
				}
			}
		}
	
		.tagFilter {
			.is-selected {
				button {
					font-weight: 400;
				}
			}
	
		}
	
		.filter-selected,
		.smartFolder-selected {
			color: rgb(0, 120, 212);
			font-weight: 600;
		}
	
		.ms-Nav-compositeLink {
			button.ms-Nav-chevronButton i {
				transform: rotate(-90deg);
			}
	
			&.is-expanded {
				button.ms-Nav-chevronButton i {
					transform: rotate(0deg);
				}
			}
		}
	
		.chevronButton {
			border: none;
			outline: transparent;
			width: 28px;
			vertical-align: top;
			margin-left: 9px;
	
			i {
				font-size: 12px;
			}
		}
	
		.is-selected {
	
			button {
				background: transparent;
	
				&:after {
					border: none;
				}
	
				i {
					color: $midGrey;
				}
			}
		}
	
		li.ms-Nav-navItem {
			//margin-bottom: 8px;
	
			.ms-Nav-navItem {
				margin-bottom: 0;
			}
		}
	
		&.second {
	
			&.filters {
				background-color: $lightestGrey;
			}
			//background: #f9fbff;
			background: white;
	
			.ms-Searchbox {
				padding: 10px;
			}
	
			.ms-DetailsRow-cell {
				//padding-left: 2px;
				padding-right: 0;
			}
			
			.ms-DetailsRow {
				//background-color: transparent;
		
				&:hover {
					background: $hoverGrey;
				}
				
				&.is-selected {
					background: $selectedGrey;
				}
		
			}
			.ms-DetailsHeader{
				//height: 54px;
				//padding-left: 2px;
				align-items: center;
				display: flex;
				background-color: white !important;
			}
	
			.ms-DetailsHeader-cellTitle {
				//height: 48px;
				//padding-left: 2px;
				padding-right: 0;
				&:hover {
					background-color: white !important;
				}
	
				.ms-DetailsHeader-cellName {
					width: 100%
				}
			}
	
			.header {
				position: absolute;
				height: 48px;
				//padding: 0 1rem 0 1rem;
				width: 100%;
				border-bottom: 1px solid $lightestGrey;
				border-right: 1px solid $lightestGrey;
	
				&.selected {
					background-color: $selectedGrey !important;
					font-weight: 600;
					color: $ms-blue;
			
					i {
						color: rgb(50, 49, 48);
					}
				}
			}
		}
	}
}

#persona-button:hover {
	cursor: pointer !important;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	background: $lightestGrey;
}

.settings-wrap,
.persona-wrap {
	position: relative;

	.webUpdateAvailable {
		color: #ffaa44;
		font-size: 0.7rem !important;
		position: absolute;
		right: 5px;
		top: 5px;
	}
}

.contextualSettingsMenu,
.contextualUserMenu {
	.userName {
		padding-top: 18px;
		
		.ms-ContextualMenu-header {
			height: 18px;
			line-height: 18px;

			/*&:hover {
				background: $hoverGrey;
			}*/
		}

		span {
			color: rgb(50, 49, 48);
			font-weight: 700;
			vertical-align: bottom;
		}
	}

	.userEmail {

		.ms-ContextualMenu-header {
			height: 24px;
			line-height: 24px;
		}

		span {
			font-size: 12px;
			color: $greyText;
		}
	}

	.important {
		button {
			background: #ffaa44 !important;

			&:hover {
				background: #ffaa44 !important;
			}
		}
	}

	.ms-ContextualMenu-divider {
		display: none;
	}
}

#settings-button.selected {
	i {
		color: $darkGrey
	}
}

#separator1 {
	&:hover {
		cursor: pointer !important;
	}
	button {
		padding: 0;
		&:hover {
			background-color: $lightestGrey;
			cursor: pointer !important;
		}

		hr {
			color: rgba(0,0,0,.5) !important;
			width: 100%;
		}
	}
}