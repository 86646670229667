body {
	height: 100%;
	max-height: 100%;
}

button, a, i {
	&:focus {
		outline: none;
	}
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

#top-titlebar {
	border-top: 1px solid grey;
	background: $darkGrey;
	height: 40px;
	//padding: 10px 12px;

	.title {

		img {
			vertical-align: text-top;
			width: 18px;
		}

		span, button, button:hover {
			color: white;
			font-size: 14px;
			font-weight: 600;
			text-decoration: none;
		}
	}

	.btn-openInDesktop {
		background-color: transparent;
		border: 1px solid white;
		border-radius: 3px;
		color: white;
		font-size: 10px;
		height: 24px;
		//right: 35px;
		margin-right: 1rem;

		&:hover {
			background-color: white;
			color: $darkGrey;
		}
	}

	.header-waffle {
		width: 48px;
		height: 39px;
		border-radius: 0;
		position: relative;
		&:hover {
			background-color: $lightGrey;
		}
	}
}

.panel-waffle {
	width: 48px;
	height: 40px;
	border-radius: 0;
	i {
		color: rgb(50, 49, 48);
	}
	&:hover {
		background-color: $hoverGrey;
	}
}

.app-selection {
	width: 100%;
	padding: 2px;
	text-decoration: none !important;

	&:hover {
		background-color: $lightestGrey;
		cursor: pointer;
	}

	&.disabled {
		cursor: default;
		pointer-events: none;
		opacity: .5;
	}

	span {
		color:rgb(50, 49, 48);
	}

	.app-icon {
		width: 48px;
		height: 48px;
		img {
			width: 28px;
			height: 28px;
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}

.capitalize {
	display: inline-block;
	text-transform: lowercase;

	&:first-letter{
  		text-transform: uppercase;
	}
}

.align-sub {
	vertical-align: sub;
}

.titlebar {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.titlebar-button {
  -webkit-app-region: no-drag;
}

.container-after-titlebar {
	height: 100vh;
}

.research-iframe {
	border: 0;
	width: 100%;
}

.list {
	height: calc(100vh - 89px - 40px);
	min-width: 0;
	overflow: auto;
	position: relative;
	width: auto;

	&.policies {
		height: calc(100vh - 89px - 100px);
	}

	&.import {
		height:	calc(100vh - 89px - 80px);
	}

	&.search {
		height: calc(100vh - 89px - 64px - 40px);
	}

	.ms-FocusZone {
		padding-top: 0;
	}
	
	.ms-DetailsRow {
		//background-color: transparent;
		&.is-selected {
			background: $selectedGrey;
		}

		&:focus:after {
			border: none;
		}
	}

	// Shimmered detailsList fix
	.ms-Shimmer-container,
	.ms-Shimmer-shimmerWrapper,
	.ms-Shimmer-shimmerGradient,
	.ms-ShimmerElementsGroup-root {
		height: 32px;
		max-width: 100%;
	}

	.offline-status {
		font-size: 60%;
		margin-left: 6px;
	}

	.inline-action-button {
		height: 16px;
		vertical-align: text-bottom;
	}
}

.add-group-dialog {

	.ms-Dialog-main {
		max-width: 460px;
		width: 100%;
	}

	.ms-Dialog-inner {
		padding: 0;
	}

	.dialog-content-wrap {
		padding: 0 24px 24px 24px;
	}

	.share-pivot {
		background-color: $lightestGrey;
		padding: 0 24px 6px 24px;
		
		.ms-FocusZone {
			text-align: center;
		}

		button {
			margin-right: 24px;

			span {
				font-weight: 700;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	h1 {
		font-size: 21px;
		font-weight: 100;
	}

	ul {
		li {
			cursor: pointer;
			font-size: 16px;
			list-style: none;

			&:hover {
				background-color: $hoverGrey;
			}

			&.selUser {
				font-size: 14px;
		
				i {
					font-size: 12px;
				}
			}

			i {
				font-size: 20px;
				font-weight: 600;
				vertical-align: sub;
			}
		}
	}
}

.activity-description {
	display: block;

	&:first-letter {
		text-transform: uppercase;
	}
}

.activity-items-callout {

	.ms-Callout-main {
		padding: 12px;

		li:last-child {
			margin-bottom: 0 !important;
		}
	}
}

.home-card {
	background-color: white;
	width: 100%;
	height: 200px;
	max-width: 230px;
}

.info-card {
	margin: 0rem .5rem 1rem .5rem;
	background-color: white;
	box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
	border: 1px solid white;
	transition-property: box-shadow;
	transition-duration: 200ms;

	.title {
		font-size: 16px;
		font-weight: 600;
	}

	.description {
		margin-top: 5px;
	}

	&:hover {
		&::after {
			border: none;
		}
		border-color: $lightGrey;
		box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
	}

	&.selected {
		box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
		border-color: $highlight;
	}
}

.separator {
	margin: 0 1rem;
}

button {
	&:focus {
		outline: none;
	}

	&.ms-Button,
	&.ms-Button:hover {
		border: none;

		&.secondaryButton {
			background-color: rgb(243, 242, 241);

			&:hover {
				background-color: $hoverGrey;
			}
		}
	}
}

.font-weight-bold {
	font-weight: 700;
}

li {
	list-style-type: none;
}

.filtersBox {
	background: $lightestGrey;
	height: auto;
	min-width: 0;
	padding: 2px 0;
	position: relative;
	margin-right: 8px;

	&.noFilterBox {
		display: none !important;
	}


	.filter,
	.filterType {

		.ms-Dropdown-title,
		.ms-Dropdown-caretDownWrapper {
			height: 28px;
			line-height: 28px;

			i {
				font-size: 80%;
				font-weight: 700 !important;
			}
		}

		.ms-Dropdown-title {
			border: none;
			background: transparent;
			font-size: 80%;
			font-weight: 700 !important;
		}

		.ms-Dropdown-items {
			.ms-Button {
				font-size: 80%;
				font-weight: 700 !important;
			}
		}

		.ms-SearchBox {
			border-color: $lightGrey;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			height: 34px;

			&:hover {
				border-color: $grey;
			}
		}

		.ms-TextField-fieldGroup {
			border-color: $lightGrey;
			height: 34px;
		}

		.filter-title {
			height: 28px;

			span {
				line-height: 28px;
				vertical-align: middle;
			}
		}
	}

	.filter {
		height: 34px;
		width: 305px;
	}
}

.session {
	background-color: white;
	box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
	border: 1px solid white;
	transition-property: box-shadow;
	transition-duration: 200ms;
	margin-bottom: 1rem;
	min-width: 500px;

	&:hover {
		&::after {
			border: none;
		}
		border-color: $lightGrey;
		box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
	}

	&.selected {
		box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
		border-color: $highlight;
	}

	.session-header {
		height: 32px;
		flex-wrap: wrap;
		flex: 1;

		hr {
			margin: 0;
		}

		.session-signout {
			height: 32px;
			&:hover {
				background-color: $hoverGrey;
			}
		}
	}

	.session-body {
		flex-wrap: wrap;
		flex: 1;
		margin: 0;
		margin-top: 2.5rem;

		div {
			width: 120px;
			margin-bottom: 1rem;
		}

		p {
			margin-bottom: 6px;
			font-weight: 600;
		}
	}

	.approve-icon {
		font-size: 20px;
		color: $ms-blue;
	}
}

.persona-link {
	display: inline-block;
	cursor: pointer;
	&:hover{
		color: $ms-blue;
	}
}

.file-droptarget {
	border: 1px solid rgb(50, 49, 48);
	border-radius: 2px;
	height: 150px;
	position: relative;
	width: 100%;

	&.addedFile {
		background-color: $themeLight !important;
	}

	* {
		pointer-events: none;
	}
	
	&.pointer-events-enabled * {
		pointer-events: auto !important;
	}

	p {
		left: 50%;
		margin: 0;
		position: absolute;
		text-align: center;
		transform: translate(-50%, -50%);
		top: 50%;
		width: 80%;

		i {
			height: 30px!important;
			line-height: 30px !important;
			vertical-align: bottom !important;
		}
	}

	span.remove {
		color: $midGrey;
		cursor: pointer;
		font-size: 0.8rem;;
		text-transform: lowercase;

		&:hover {
			text-decoration: underline;
		}
	}

	button {
		pointer-events: auto;
	}
}

.subscribeNotifications {
	background: white;
	position: relative;

	.ms-Label {
		//border-bottom: 1px solid $ms-lightGrey;
		font-size: 14px;
		font-weight: 600;
		color: $ms-darkGrey;
		padding-bottom: 10px;
		padding-top: 10px;
	}

	.category {
		border-bottom: 1px solid $ms-lightGrey;
		height: 44px;
		padding-bottom: 12px;
		padding-top: 12px;

		.ms-Checkbox-label {
			margin: 0;
		}

		.category-label {
			min-width: 300px;
		}

		.category-label,
		.category-header,
		.category-header2,
		.checkbox {
			min-width: 250px;
		}

		.category-header2 {
			text-align: center;
			padding: 12px 0;
			border-bottom: 1px solid $ms-lightGrey;
		}

		.checkbox {
			display: flex;
			justify-content: center;
			padding: 12px 0;
			border-bottom: 1px solid $ms-lightGrey;
			&.last {
				border-bottom: none;
			}
		}

		&.last {
			border-bottom: none;
		}
	}
}

.policies-footer {
	height: 60px;
	border-top: 1px solid $lightestGrey;
	display: flex;

	.ms-Button--primary {
		margin-left: auto;
		margin-right: 12px;
	}
}

.form-dialog {

	.ms-Dialog-header {
		.ms-Dialog-title {
			padding-top: 8px;
			padding-bottom: 8px;
		}

		.ms-Button {
			top: -8px;
			left: 8px;	
		}
	}

	.ms-Dialog-main {
		//min-height: 0;
		height:100%;
		max-width: 800px;
		width: 100%;
	}

	.ms-Dialog-inner {
		padding-right: 0;
		padding-left: 0;
		padding-bottom: 8px;

		form {
			padding-left: 24px;
		}

		.ms-Dialog-actions {
			padding-right: 24px;
			padding-top: 8px;
			margin-top: 0;
			border-top: 1px solid $lightGrey;
		}
	}

	.action-border,
	.ms-Button--default {
		border: 1px solid $lightGrey;
		&:hover {
			border: 1px solid $lightGrey;
		}
	}

	aside, span.spinner {
		display: inline-table;
	}

	span.spinner {
		vertical-align: baseline;
	}

	.ms-Dialog--close:not(.ms-Dialog-header) {
		height: 100%;
		flex-direction: column;
		display: flex;

		.ms-Dialog-inner{
			overflow-y: hidden;
			flex-grow: 1;
			display: flex;
			flex-direction: column;

			.ms-Dialog-content {
				flex-grow: 1;
			}
		}
	}

	.ms-Pivot {
		.ms-Button {
			height: 32px;
		}
	}

}

.relation-list {
	.ms-ScrollablePane--contentContainer {
		height: 100%;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		overflow-y: overlay;
	}

	.ms-DetailsHeader {
		padding-top: 0;

		.ms-DetailsHeader-cellTitle {
			padding-left: 8px;;
		}
	}

	.ms-DetailsRow-cell {
		cursor: pointer;
		.remove-onhover {
			display: none;
		}
		&:hover {
			background-color: $hoverGrey;
			.remove-onhover {
				display: block;
			}
		}
		min-height: 47px;
	}
	::-webkit-scrollbar-track {
		background-color: transparent;
	}
	.ms-DetailsRow-cell  {
		padding-top: 0;
		padding-bottom: 0;
	}

	.height-fix {
		height: 42px;
	}
}

.list-sidebar {
	min-width: 0;
	overflow: visible;
	position: relative;

	.ms-FocusZone {
		padding-top: 0;
	}
	
	.ms-DetailsRow {

		&:hover {
			background: rgb(243, 242, 241);
		}

		&.is-selected {
			background: $selectedGrey;

			.span-title {
				color: $ms-blue;
			}
		}

		.span-title.unread {
			span,a,button,div {
				font-weight: 600 !important;
				//color: rgb(16, 110, 190)
			}
		}

		.subtitle.unread {
			color: rgb(16, 110, 190)
		}

		&:focus:after {
			border: none;
		}
	}

	.ms-DetailsHeader-cell  {
		background-color: white !important;
	}

	div, span {
		user-select: none;
	}

	&.emails {
		.ms-DetailsHeader-cellTitle {
			padding-left: 16px;
		}
		.ms-DetailsRow-check {
			margin-top: 10px;
		}
		.ms-DetailsHeader-check {
			margin-top: 0;
		}
		.ms-DetailsRow-cell {
			overflow: unset;
		}
		.ms-DetailsRow {
			&:hover {
				.file-type,
				.ms-Persona {
					display: none;
				}
			}
			span {
				color: black;
			}
			.ms-Persona {
				span {
					color: white;
				}
			}
		}
		.ms-Check-checkHost {
			margin-left: 0px;
		}
		.ms-Check {
			margin-left: 18px;
		}
	}

	&.notifications {
		.ms-DetailsRow-cell {
			padding: 6px 0px;
		}
		.ms-DetailsRow-check {
			margin: 0;
		}
	}

	.ms-DetailsHeader-cellTitle {
		padding-left: 16px;
	}
	&.main-entity {
		.ms-DetailsRow-check {
			margin-top: 0px;
		}
	}
	.ms-DetailsHeader-check {
		margin-top: 0;
	}
	.ms-DetailsRow-cell {
		overflow: unset;
		padding-top: 4px;
		padding-bottom: 4px; 
	}
	.ms-DetailsRow {
		&:hover {
			.file-type,
			.activity-icon,
			.ms-Persona {
				display: none !important;
			}
		}
		span {
			color: black;
		}
		.ms-Persona {
			span {
				color: white;
			}
		}
	}
	.ms-Check-checkHost {
		margin-left: 0px;
	}
	.ms-Check {
		margin-left: 22px;
	}
	
	&.filter {
		.ms-DetailsRow-check {
			margin-top: 0;
		}

		.ms-FocusZone {
			width: 100%;
		}

		.ms-DetailsRow-fields {
			width: calc(100% - 48px);
	
			.ms-DetailsRow-cell {
				width: 100% !important;
				overflow-x: hidden;
				margin: auto;
			}
		}
	}
}

.header-card {
	background-color: white;
	border-bottom: 1px solid $lightestGrey;
	height: 48px;
	padding: .5rem 1rem;
}

.activity-line {
	border-left: 2px solid $lightGrey;
	height: 10px;
	margin-left: 64px;
}

.activity-content {
	display: flex;
	margin: 0px 24px 0px 64px;
	border-left: 2px solid $lightGrey;
	min-height: 40px;
	padding-bottom: 10px;

	&.no-border {
		border-color: transparent;
	}
	
	.activity-icon {
		width: 28px;
		height: 28px;
		min-width: 28px;
		min-height: 28px;
		background-color: $lightGrey;
		display: flex;
		border: 2px solid rgb(250, 249, 248);
		border-radius: 16px;
		position: relative;
		left: -15px
	}

	.fui-Persona__primaryText {
		font-weight: 600;
		&:hover {
			cursor: pointer;
		}
	}
}

.messages-username {
	font-weight: 600;
	&:hover {
		cursor: pointer;
	}
}

.users-list {
	.ms-DetailsHeader {
		height: 82px;
	}
}