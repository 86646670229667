.details-panel {
	background: $lightestGrey;
	border-left: 1px solid $ms-lightGrey;
	font-size: 12px;
	height: calc(100vh - 129px);
	overflow: auto;
	z-index: 1;

	.ms-Spinner {
		display: inline-table;
	}

	.tabs-wrap {
		background: $lightestGrey;
		overflow-x: auto;
		overflow-y: hidden;
	}

	.pivot-item-icon-only {

		.ms-FocusZone {
			text-align: center;
		}

		.ms-Pivot-icon {
			i {
				font-size: 1.6em;
			}
		}

		button {
			margin-right: 16px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.nav-item {

		a.nav-link {
			color: $darkGrey;
			cursor: pointer;
			font-weight: bold;

			&.active {
				color: $ms-blue;
			}
		}
	}

	.close-wrap {
		position: absolute;
		right: 0;
		top: 12px;
		z-index: 1;
	}

	.close-button {
		color: $grey;
		cursor: pointer;
		font-size: 14px;

		&:hover {
			color: $darkGrey;
		}
	}

	.tab-title {
		background: $lightestGrey;
		border-bottom: 1px solid $lightGrey;
		padding-top: 11px;
		padding-bottom: 11px;
	}

	.form-wrap {
		background: $lightestGrey;
		top: 98px;
	}

	.file-name {
		align-self: center;
	}

	i {
		vertical-align: top;

		&.file-type {
			height: 48px;
			width: 48px;

			&.repoIcon {
				color: $ms-blue;
				font-size: 48px;
				line-height: 48px;
				vertical-align: baseline;
			}

			&.trash {
				font-size: 3.2em;
				text-align: center;
				vertical-align: super;
			}
		}

		&.bookmark {
			cursor: pointer;
		}
	}

	span.bookmark {
		width: 14px;

		.ms-Spinner {
			margin-top: 1px;
		}
	}

	ul {
		li {
			list-style: none;
		}

		&.tags {
			li.tag {
				display: inline-table;
				margin: 6px 6px 0 0;

				span {
					cursor: pointer;
					font-size: 12px;

					.delete {
						font-size: 8px;
						vertical-align: baseline;

						&:hover {
							color: red;
						}
					}
				}
			}
		}

		&.comments {
			li.comment {
				background: white;
				display: inline-table;
				margin: 6px 6px 0 0;
				position: relative;
				width: 100%;

				.delete {
					cursor: pointer;
					font-size: 8px;
					vertical-align: baseline;

					&:hover {
						color: red;
					}
				}
			}
		}

		&.versions {
			li.commit {
				list-style: square;
			}
		}

		&.annotations {
			li.annotation {
				background: white;
				cursor: pointer;

				highlight {
					background: $marker;
				}
			}
		}

		&.coincidences {

			li.coincidence {
				background: white;
				cursor: pointer;

				highlight {
					background: $marker;
				}
			}
		}
	}

	.list-versions {

		.ms-FocusZone {
			padding-top: 0;
		}

		.ms-DetailsHeader-cell {
			padding: 6px 12px;

			&:hover {
				background: inherit;
			}
		}

		.ms-DetailsRow {
			cursor: pointer;

			&:hover {
				background: $lightGrey;
			}
		}

		.badge {
			font-size: 12px;
			width: 70px;
		}

		.date {
			font-size: 14px;
		}

		p {
			font-size: 14px;
		}
	}

		// .list-versions {
	// 	background: transparent;

	// 	.ms-FocusZone {
	// 		background: transparent;
	// 		padding-top: 0;
	// 	}

	// 	.ms-DetailsHeader-cell {
	// 		background: transparent;
	// 	}

	// 	.ms-DetailsRow {
	// 		cursor: pointer;
			
	// 		&:hover {
	// 			background: white;
	// 		}
	// 	}
	// }

	.lead {
		font-size: 14px;
		word-break: break-word;
	}

	.permissions-title {
		display: inline-block;
		width: 48px;
	}

	.toggle-permissions-list {
		cursor: pointer;
		font-size: 8px;
	}

	.persona {
		
		a, p {
			color: $darkGrey;
			font-size: 12px;
			vertical-align: top;
		}

		.remove-permissions {
			cursor: pointer;
			font-size: 10px;
		}
	}

	.size-trigger {
		span {
			font-size: 12px;
			margin: 0;
			padding: 0;
		}
	}

	.citation {
		button {
			border: none;
			font-size: 12px;
			padding-left: 0;

			i {
				font-size: 12px;
				margin-left: 0;
			}
		}
	}

	p.share-section {
		font-size: 0.9rem;
		font-weight: 650;
	}
}