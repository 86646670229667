.modal-wrapper {
	background-color: #faf9f8;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: 40px;
	height: calc(100% - 40px);

	&.no-background {
		background-image: none;
	}

	.title {
		vertical-align: sub;
	}

	.lead {
		font-weight: 600;
	}

	.form-modal {
		max-width: 480px;
		width: 90%;
	}

	button {
		&:focus {
			outline: none;
		}
	
		&.ms-Button,
		&.ms-Button:hover {
			border: none;
	
			&.secondaryButton {
				background-color: rgb(243, 242, 241);
	
				&:hover {
					background-color: $hoverGrey;
				}
			}
		}
	}

	.scrollable-modal-content {
		max-height: 100%;
		overflow: hidden;
	}

	.ms-TextField-fieldGroup::before {
		right: 0;
	}
}

img.logo {
	width: 152px;
}

.register {
	font-size: 0.8em;
}

.fake-url {
	color: $ms-blue;

	&:hover {
		color: $themePrimary;
		cursor: pointer;
		text-decoration: underline;
	}
}